import React from "react";
import { useTheme } from "styled-components";

import androidDark from "../../images/stores/android-dark.png";
import androidLight from "../../images/stores/android-light.png";

export default function AndroidIcon() {
  const { isDark } = useTheme();
  if (isDark) {
    return <img src={androidDark} alt="Android" />;
  }
  return <img src={androidLight} alt="Android" />;
}