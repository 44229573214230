import React from "react"
import PropTypes from "prop-types"
import { ButtonBack } from "../common/button"
import ProjectFooter from "./project-footer"
import "./project-container.scss"

const ProjectContainer = ({ children }) => {
  return (
    <div className="project-container">
      <div className="sg-container back-btn-container">
        <div className="row">
          <div className="col-12">
            <ButtonBack />
          </div>
        </div>
      </div>
      {children}
      {/* <ProjectFooter /> */}
    </div>
  )
}

ProjectContainer.propTypes = {
  children: PropTypes.node,
}

export default ProjectContainer
