import React from "react";
import { useTheme } from "styled-components";

import webDark from "../../images/stores/web-dark.png";
import webLight from "../../images/stores/web-light.png";

export default function WebIcon() {
  const { isDark } = useTheme();
  if (isDark) {
    return <img src={webDark} alt="Web" />;
  }
  return <img src={webLight} alt="Web" />;
}