import React from "react"
import PropTypes from "prop-types"

import "./tag.scss"

const Tag = ({ color, children }) => {
  return (
    <div className="sg-tag" style={{ backgroundColor: color }}>
      {children}
    </div>
  )
}

Tag.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
}

export default Tag
