import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-intl"
import { useTheme } from "styled-components"
import IntlText from "./intl-text-2"
import ButtonStyled from "../button";
import { isServer } from "../../utils"
import "./button.scss"

const Button = ({ className, linkTo, textId, ...others }) => {
  if (linkTo) {
    return (
      <Link to={linkTo} className={`sg-button ${className}`} {...others}>
        <IntlText id={textId} component="span" />
      </Link>
    )
  }
  return (
    <div className={`sg-button ${className}`} {...others}>
      <IntlText id={textId} component="span" />
    </div>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  textId: PropTypes.string,
  disabled: PropTypes.bool,
}

export const ButtonBlue = props => {
  return <Button className="btn-blue" {...props} />
}

ButtonBlue.propTypes = {
  linkTo: PropTypes.string,
  textId: PropTypes.string,
}

export const ButtonWhite = props => {
  return <Button className="btn-white" {...props} />
}

ButtonWhite.propTypes = {
  linkTo: PropTypes.string,
  textId: PropTypes.string,
}

export const ButtonBack = () => {
  const theme = useTheme();
  return (
    <ButtonStyled
      size="big"
      variant="outlined"
      bgColor={theme.body}
      color={theme.buttonColor}
      onClick={() => {
        if (!isServer()) {
          window.history.back()
        }
      }}
    >
      <IntlText id="back" />
    </ButtonStyled>
  )
}
