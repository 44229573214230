import React from "react"
import IntlText from "../../components/common/intl-text-2"
import Layout from "../../components/layout"
import Text from "../../components/common/text"
import ProjectContainer from "../../components/projects/project-container"
import ProjectIntro from "../../components/projects/project-intro"
import ProjectContent from "../../components/projects/project-content"
import LogoOldImage from "../../components/projects/agitagym-images/logo-old"
import LogoNewImage from "../../components/projects/agitagym-images/logo-new"
import IconImage from "../../components/projects/agitagym-images/icon"
import Illustration1Image from "../../components/projects/agitagym-images/illustration-1"
import Illustration2Image from "../../components/projects/agitagym-images/illustration-2"
import Illustration3Image from "../../components/projects/agitagym-images/illustration-3"
import Illustration4Image from "../../components/projects/agitagym-images/illustration-4"
import Illustration5Image from "../../components/projects/agitagym-images/illustration-5"
import Screenshot1Image from "../../components/projects/agitagym-images/screenshot-1"
import Screenshot2Image from "../../components/projects/agitagym-images/screenshot-2"
import Screenshot3Image from "../../components/projects/agitagym-images/screenshot-3"
import Screenshot4Image from "../../components/projects/agitagym-images/screenshot-4"
import LogoImage from "../../components/projects/agitagym-images/logo"
import BackgroundImage from "../../components/projects/agitagym-images/background"
import ScratchImage from "../../components/projects/agitagym-images/scratch"
import ConfigImage from "../../components/projects/agitagym-images/config"
import ConfigMobileImage from "../../components/projects/agitagym-images/config-mobile"
import NutritionalImage from "../../components/projects/agitagym-images/nutritional"
import NutritionalMobileImage from "../../components/projects/agitagym-images/nutritional-mobile"
import PhysicalActivityImage from "../../components/projects/agitagym-images/physical-activity"
import PhysicalActivityMobileImage from "../../components/projects/agitagym-images/physical-activity-mobile"
import FeaturesImage from "../../components/projects/agitagym-images/features"
import FeaturesMobileImage from "../../components/projects/agitagym-images/features-mobile"
import AndroidIcon from "../../components/icons/AndroidIcon"
import AppleIcon from "../../components/icons/AppleIcon"
import WebIcon from "../../components/icons/WebIcon"

const tags = ["Product Thinking", "UX / UI", "Backend", "Frontend", "Mobile"]

const stores = [
  {
    store: "android",
    icon: <AndroidIcon />,
    link: "https://play.google.com/store/apps/details?id=com.agitagym.app",
  },
  {
    store: "apple",
    icon: <AppleIcon />,
    link: "https://itunes.apple.com/ar/app/agita-gym/id1403889648?mt=8",
  },
  {
    store: "web",
    icon: <WebIcon />,
    link: "https://app.agitagym.com",
  },
]

const technologies = "React, React Native, Node.js"

const HowWeHelp = () => {
  return (
    <div className="row how-we-help">
      <div className="col-12 col-lg-6 left-column">
        <IntlText id="agitaGym.body2" component="h1" />
      </div>
      <div className="col-12 col-lg-6 right-column">
        <IntlText id="agitaGym.body3" component="h2" />
      </div>
    </div>
  )
}

const WhatWeDo = () => {
  return (
    <div className="row what-we-do">
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="technologies" />
        <Text>
          <strong>{technologies}</strong>
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="platforms" />
        <Text>
          <IntlText id="agitaGym.platforms" component="strong" />
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="services" />
        <Text>
          <IntlText id="agitaGym.services" component="strong" />
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="projectContent.explore" />
        <div className="store-icons">
          {stores.map((value, key) => (
            <a
              key={key}
              href={value.link}
              target="_blank"
              rel="noopener noreferrer"
              title={value.store}
            >
              {value.icon}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

const LogoRedesign = () => {
  return (
    <div className="project-redesign">
      <div className="row">
        <div className="col-6">
          <Text id="projectContent.oldLogo" />
          <LogoOldImage className="redesign-image" />
        </div>
        <div className="col-6">
          <Text id="projectContent.newLogo" />
          <LogoNewImage className="redesign-image" />
        </div>
      </div>
    </div>
  )
}

const Banner = () => {
  return (
    <div className="project-banner agita-gym">
      <IconImage className="banner-image" />
    </div>
  )
}

const Illustrations = () => {
  return (
    <>
      <div className="illustration-container">
        <div className="row">
          <div className="col-4">
            <Illustration1Image className="illustration-image" />
          </div>
          <div className="col-4">
            <Illustration2Image className="illustration-image" />
          </div>
          <div className="col-4">
            <Illustration3Image className="illustration-image" />
          </div>
        </div>
      </div>
      <div className="illustration-container-full">
        <Illustration4Image className="d-xs-none d-sm-block illustration-img-full" />
        <Illustration5Image className="d-sm-none illustration-img-full" />
      </div>
    </>
  )
}

const Screenshots = () => {
  return (
    <div className="screenshots-container agita-gym">
      <div className="images">
        <Screenshot1Image className="screenshot-img" />
        <Screenshot2Image className="screenshot-img" />
        <Screenshot3Image className="screenshot-img" />
        <Screenshot4Image className="screenshot-img" />
      </div>
    </div>
  )
}

const AgitaGymPage = () => {
  return (
    <Layout
      pageTitle="Projects"
      title="Agita Gym´s software development challenge - Sysgarage"
      description="Check out the software development project for Agita Gym that our company has successfully tackled. ​Let us show you how we can help!"
      hideHeader
      noMargin
      hideContact
    >
      <ProjectContainer>
        <div className="sg-container">
          <ProjectIntro
            tags={tags}
            logo={<LogoImage className="logo-image" />}
            textId="agitaGym.body1"
            background={<BackgroundImage className="background-image" />}
          />
          <HowWeHelp />
          <WhatWeDo />
        </div>
        <ProjectContent
          titleId="agitaGym.body4"
          descriptionId="agitaGym.body5"
          image={<ScratchImage />}
          imageMobile={<ScratchImage />}
        />
        <ProjectContent
          titleId="agitaGym.body6"
          descriptionId="agitaGym.body7"
          image={<ConfigImage />}
          imageMobile={<ConfigMobileImage />}
          imageFullWidth
        />
        <ProjectContent
          titleId="agitaGym.body8"
          descriptionId="agitaGym.body9"
          image={<NutritionalImage />}
          imageMobile={<NutritionalMobileImage />}
        />
        <ProjectContent
          titleId="agitaGym.body10"
          descriptionId="agitaGym.body11"
          image={<PhysicalActivityImage />}
          imageMobile={<PhysicalActivityMobileImage />}
        />
        <ProjectContent titleId="agitaGym.body12">
          <LogoRedesign />
        </ProjectContent>
        <Banner />
        <ProjectContent titleId="agitaGym.body13">
          <Illustrations />
        </ProjectContent>
        <ProjectContent
          titleId="agitaGym.body14"
          descriptionId="agitaGym.body15"
          image={<FeaturesImage />}
          imageMobile={<FeaturesMobileImage />}
          imageFullWidth
        />
        <ProjectContent
          titleId="agitaGym.body16"
          descriptionId="agitaGym.body17"
        >
          <Screenshots />
          <div className="blank-space" />
        </ProjectContent>
      </ProjectContainer>
    </Layout>
  )
}

export default AgitaGymPage
