import React from "react"
import PropTypes from "prop-types"
import IntlText from "../common/intl-text-2"
import Text from "../common/text"
import "./project-content.scss"

const ProjectContent = ({
  descriptionId,
  titleId,
  image,
  imageMobile,
  imageFullWidth,
  hasHtml,
  children,
}) => {
  return (
    <div className="project-content">
      <div className="content-box">
        {titleId && (
          <div className="title">
            <IntlText id={titleId} />
          </div>
        )}
        {descriptionId && (
          <div className="description">
            <Text id={descriptionId} />
          </div>
        )}
      </div>
      <div
        className={`image-box wow fadeInUp ${
          imageFullWidth ? "full-width" : ""
        }`}
      >
        {image && <image.type {...image.props} className="d-none d-md-block" />}
        {imageMobile && (
          <imageMobile.type {...imageMobile.props} className="d-md-none" />
        )}
      </div>
      {children}
    </div>
  )
}

ProjectContent.propTypes = {
  descriptionId: PropTypes.string,
  titleId: PropTypes.string,
  image: PropTypes.any,
  imageMobile: PropTypes.any,
  imageFullWidth: PropTypes.bool,
  hasHtml: PropTypes.bool,
}

export default ProjectContent
