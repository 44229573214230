import React from "react"
import PropTypes from "prop-types"
import Colors from "src/constants/Colors"
import Tag from "../common/tag"
import Text from "../common/text"
import "./project-intro.scss"

const ProjectIntro = ({ tags, logo, textId, background }) => {
  return (
    <div className="row padded project-intro">
      <div className="col-12 col-lg-6">
        <div className="tags-container">
          {tags && tags.map((value, key) => (
            <Tag color={Colors.tag} key={key}>
              {value}
            </Tag>
          ))}
        </div>
        <div className="logo-container">{logo}</div>
        <div className="body">
          <Text id={textId} />
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <div className="background-container wow slideInRight">{background}</div>
      </div>
    </div>
  )
}

ProjectIntro.propTypes = {
  tags: PropTypes.array,
  logo: PropTypes.any,
  textId: PropTypes.string,
  background: PropTypes.any,
}

export default ProjectIntro
