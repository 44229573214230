import React from "react"
import { useTheme } from "styled-components";
import { AGITA_GYM_ALT } from "../../../constants/Constants"

const image = require("../../../images/agitagym/logo-old.png")
const imageDark = require("../../../images/agitagym/logo-old-dark.png")

const LogoOldImage = props => {
  const { isDark } = useTheme();
  if (isDark) {
    return <img src={imageDark} alt={AGITA_GYM_ALT} {...props} />
  } 
  return <img src={image} alt={AGITA_GYM_ALT} {...props} />
}

export default LogoOldImage
