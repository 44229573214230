import React from "react"
import { AGITA_GYM_ALT } from "../../../constants/Constants"

const image = require("../../../images/agitagym/features.png")

const FeaturesImage = props => {
  return <img src={image} alt={AGITA_GYM_ALT} {...props} />
}

export default FeaturesImage
