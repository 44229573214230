import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import "./text.scss"

const Text = ({ id, bold, centered, className, children }) => {
  const intl = useIntl()
  const text = id ? intl.formatMessage({ id }) : children
  return (
    <p
      className={`sg-text ${centered ? "centered " : ""}${bold ? "bold " : ""}${
        className || ""
      }`}
    >
      {text}
    </p>
  )
}

Text.propTypes = {
  bold: PropTypes.bool,
  centered: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Text
