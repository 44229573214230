import React from "react";
import { useTheme } from "styled-components";

import appleDark from "../../images/stores/ios-dark.png";
import appleLight from "../../images/stores/ios-light.png";

export default function AppleIcon() {
  const { isDark } = useTheme();
  if (isDark) {
    return <img src={appleDark} alt="iOS" />;
  }
  return <img src={appleLight} alt="iOS" />;
}