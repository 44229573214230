export const HOME_PAGE = "/";
export const SERVICES_PAGE = "/services";
export const PROJECTS_PAGE = "/projects";
export const CULTURE_PAGE = "/culture";
export const CONTACT_PAGE = "/contact"
export const SERVICE_PRODUCT_TEAM_PAGE = "/services/product-team";
export const SERVICE_STAFF_AUGMENTATION_PAGE = "/services/staff-augmentation";
export const PROJECT_AGITA_GYM_PAGE = "/projects/agitagym";
export const PROJECT_BASAPP_PAGE = "/projects/basapp";
export const PROJECT_DERMACLINIC_PAGE = "/projects/dermaclinic";
export const PROJECT_FUDEX_PAGE = "/projects/fudex";
export const PROJECT_GLOBALTILL_PAGE = "/projects/globaltill";
export const PROJECT_ZYN_PAGE = "/projects/zyn";
export const SERVICE_FRONTEND_PAGE = "/services/frontend-developer";
export const SERVICE_MOBILE_PAGE = "/services/mobile-developer";
export const SERVICE_FULLSTACK_PAGE = "/services/fullstack-developer";