import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import "./text.scss"

const IntlText = ({ id, component, ...others }) => {
  const intl = useIntl()
  const text = id ? intl.formatMessage({ id }, {
    strong: (...chunks) => <strong>{chunks}</strong>,
  }) : "";
  switch (component) {
    case "p":
      return <p {...others}>{text}</p>
    case "span":
      return <span {...others}>{text}</span>
    case "strong":
      return <strong {...others}>{text}</strong>
    case "h4":
      return <h4 {...others}>{text}</h4>
    case "h3":
      return <h3 {...others}>{text}</h3>
    case "h2":
      return <h2 {...others}>{text}</h2>
    case "h1":
      return <h1 {...others}>{text}</h1>
    default:
      return text
  }
}

export default IntlText
